.carQuestionContainer {
  color: white;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
  padding-bottom: 1rem;
}

.carQuestionContainer p {
  color: white;
  font-size: 17px;
  padding-left: 5px;
  margin-bottom: 10px;
}

.carQuestionContainer h2 {
  color: white;
}

.formControl {
  width: 400px;
  margin-bottom: 2rem;
}

.formInput {
  width: 100%;
  border-radius: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.selectBox {
  width: 100%;
}

.selectBox .ant-select-selector {
  border-radius: 20px !important;
  height: auto !important;
  padding: 10px !important;
}

.nextBtn {
  width: 100%;
  max-width: 200px;
  cursor: pointer;
  background: #7551a8;
  background: -webkit-linear-gradient(to right, #6876e0, #7551a8);
  background: linear-gradient(to right, #6876e0, #7551a8);
  border: none;
  border-radius: 3px;
  width: 100%;
  color: #fff;
  height: auto;
  padding: 10px;
  margin: 10px 0 0 10px;
}
