@import url("https://fonts.googleapis.com/css2?family=Muli:wght@900&display=swap");

body {
  background-color: #000;
  font-family: "Roboto", sans-serif;
  height: 100%;
}

.centerText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  height: 100vh;
}
.lableText {
  color: #fff;
  font-size: 18px;
  text-align: center;
  width: 80%;
}
.warningText {
  color: #ff9800;
  font-size: 12px;
}
.optionButton {
  width: 70%;
  cursor: pointer;
}
@media only screen and (max-width: 480px) {
  .optionButton {
    width: 100%;
    cursor: pointer;
  }
}
.option1 {
  background: #7551a8;
  cursor: pointer;

  background: -webkit-linear-gradient(to right, #6876e0, #7551a8);
  background: linear-gradient(to right, #6876e0, #7551a8);
  border: none;
  border-radius: 3px;
  width: 100%;
  color: #fff;
  height: auto;
  padding: 20px 10px 20px 10px;
  margin-top: 10px;
}
.option2 {
  background: #165a7f;
  cursor: pointer;
  height: 45px;

  background: -webkit-linear-gradient(to right, #165a7f, #228fac);
  background: linear-gradient(to right, #165a7f, #228fac);
  border: none;
  border-radius: 3px;
  width: 100%;
  color: #fff;
  height: auto;
  padding: 20px 10px 20px 10px;
  margin-top: 10px;
}
.option3 {
  background: #149e91;
  background: -webkit-linear-gradient(to right, #149e91, #02c9ac);
  background: linear-gradient(to right, #149e91, #02c9ac);
  cursor: pointer;

  border: none;
  border-radius: 3px;
  width: 100%;
  color: #fff;
  height: auto;
  padding: 20px 10px 20px 10px;
  margin-top: 10px;
}

.btnwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .prebutton {
  background-color: #999;
  background-image: -webkit-linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 0%, 0.1)
  );
  background-image: -moz-linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 0%, 0.1)
  );
  background-image: -ms-linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 0%, 0.1)
  );
  background-image: -o-linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 0%, 0.1)
  );
  background-image: linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 0%, 0.1)
  );
  border: none;
  border-radius: 0.5em;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.25),
    inset 0 2px 0 hsla(0, 0%, 100%, 0.1), inset 0 1.2em 0 hsla(0, 0%, 100%, 0.1),
    inset 0 -0.2em 0 hsla(0, 0%, 100%, 0.1),
    inset 0 -0.25em 0 hsla(0, 0%, 0%, 0.5), 0 0.25em 0.25em hsla(0, 0%, 0%, 0.1);
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0.5em 1em;
  padding: 0.5em 1.5em 0.75em;
  position: relative;
  text-decoration: none;
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.25);
  vertical-align: middle;
}
.prebutton:hover {
  outline: none;
}
.prebutton:hover,
.prebutton:focus {
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.25),
    inset 0 2px 0 hsla(0, 0%, 100%, 0.1), inset 0 1.2em 0 hsla(0, 0%, 100%, 0.1),
    inset 0 -0.2em 0 hsla(0, 0%, 100%, 0.1),
    inset 0 -0.25em 0 hsla(0, 0%, 0%, 0.5),
    inset 0 0 0 3em hsla(0, 0%, 100%, 0.2), 0 0.25em 0.25em hsla(0, 0%, 0%, 0.1);
}
.prebutton:active {
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.25),
    inset 0 2px 0 hsla(0, 0%, 100%, 0.1), inset 0 1.2em 0 hsla(0, 0%, 100%, 0.1),
    inset 0 0 0 3em hsla(0, 0%, 100%, 0.2),
    inset 0 0.25em 0.5em hsla(0, 0%, 0%, 0.05), 0 -1px 1px hsla(0, 0%, 0%, 0.1),
    0 1px 1px hsla(0, 0%, 100%, 0.25);
  outline: none;
  padding-bottom: 0.5em;
} */

/* Regular */

.speakbtn {
  padding: 16px 10px 10px 10px;
  background-color: #ff482a;
  width: 60px;
  height: 60px;
  border-radius: 35px;
  cursor: pointer;
}

.submitbtn {
  text-align: center;
  padding: 16px 10px 10px 10px;
  background-color: #04aa6d;
  width: 60px;
  height: 60px;
  border-radius: 35px;
  cursor: pointer;
}

.submitWrap {
  width: 80px;
  margin-top: 10px;
}

.quizecontainer {
  width: 100%;
}

.red {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.emotion {
  width: 200px;
}

.emotionwrap {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.App {
  height: 100vh;
}

.infinity-loader {
  position: fixed; /*We have to use it as we are using absolute positioning on its children and we will align it in the center of the page*/
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /*yes, we have to define width and height, otherwise transformation won't work*/
  width: 130px; /* 2 x width - border-width */
  height: 70px; /* width */
  z-index: 5;
}

.infinity-loader .bg div,
.infinity-loader > .fg > div > div {
  width: 70px; /* width */
  height: 70px; /* width */
  border: 10px solid #aaa; /* border-width solid #aaa */
  box-sizing: border-box; /* so that its border won't increase its width*/
  border-radius: 50%; /* to make the div round*/
  position: absolute;
}

.infinity-loader .right-bg {
  transform: translate(100%, 0);
  left: -10px; /* -border-width */
}

.infinity-loader > .fg > div > div {
  border-color: #1260ac #1260ac transparent transparent;
  transform: rotate(135deg);
  animation: spin 1s linear infinite; /* spin time linear infinite */
  position: static; /*add this otherwise transformation in its parent won't work as expect*/
}

.infinity-loader > .fg > div {
  clip: rect(0, 70px, 35px, 0); /* 0, width, width/2, 0*/
  position: absolute; /* required for using clip: rect() */
}

.infinity-loader > .fg > .bottom-right-rect {
  left: -10px; /* -border-width */
  transform: translateX(100%) scale(1, -1);
}

.infinity-loader > .fg > .bottom-right-rect > div {
  animation-delay: 0.25s; /* time/4 */
}

.infinity-loader > .fg > .top-right-rect {
  left: -10px; /* -border-width */
  transform: translateX(100%) scale(-1, 1);
}

.infinity-loader > .fg > .top-right-rect > div {
  animation-delay: 0.5s; /* (2 x time)/4 */
}

.infinity-loader > .fg > .bottom-left-rect {
  transform: scale(-1);
}

.infinity-loader > .fg > .bottom-left-rect > div {
  animation-delay: 0.75s; /* (3 x time)/4 */
}

.infinity-loader > .fg {
  filter: drop-shadow(0 0 6px #1260ac);
}

@keyframes spin {
  50%,
  100% {
    transform: rotate(495deg);
  } /* (360 + 135)deg*/
}

.secretText {
  color: #fff;
  text-align: center;
  padding: 8px 12px 8px 12px;
  border: 2px solid #fff;
  width: 180px;
  font-weight: bolder;
  border-radius: 25px;
  cursor: pointer;
}
.secretTextTitle {
  /* display: flex;
  flex-direction: row; */
  margin-bottom: 50px;

  color: #fff;
  cursor: pointer;
  font-weight: bolder;
  padding: 8px 12px;
  text-align: center;
}

.secretwrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;

  color: #fff;
  cursor: pointer;
  font-weight: bolder;
  padding: 8px 12px;
}
.exolifeText {
  font-family: "Muli";
  font-size: 80px;
  font-weight: 700;
  /* margin-left: 9%; */
}

.emospark {
  font-family: "Muli";
  font-size: 30px;
  font-weight: 700;
  /* margin-left: 6px; */
  /* margin-top: 72px; */
}

@media only screen and (max-width: 750px) {
  .exolifeText {
    font-size: 80px;
    /* flex-direction: column; */
    /* display: flex; */
  }

  .emospark {
    font-size: 20px;
  }
  .emospark {
    margin-top: 0px;
    text-align: center;
  }
}
.secretTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thankyou {
  color: #fff;
}

.inputfield {
  width: 75%;
  border-radius: 20px;
  padding: 10px;
}

@media only screen and (max-width: 750px) {
  .inputfield {
    width: 300px !important;
    /* display: flex; */
  }
}
.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.videoContainer {
  width: 100%;
  height: 100%;
}

.iframevideo {
  width: 100%;
  height: 100%;
}

.secretInput {
  width: 340px;
  border-radius: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.redcolor {
  color: red;
}

.fullWidth {
  width: 100%;
}

.secretNextBtn {
  border-radius: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.speakbtnwrapper {
  width: 150px;
  margin-bottom: 10px;
}

.speakNextBtn {
  padding: 10px;
}

.progressWrapper {
  display: flex;
}

.progressComponent {
  width: 40%;
  margin: 0px 10px;
}

.threejsbackground {
  position: absolute;
  z-index: -1;
}

.createSpark {
  text-align: center;
  font-size: 20px;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
}

@media (max-width: 480px) {
  .secretTextTitle {
    margin-bottom: 20px;
    padding: 8px 12px;
  }
  .exolifeText {
    font-size: 40px;
  }
}